
import Vue from "vue";
import ContasImporteList from "@/components/subviews/contas-importe/contas-importe-list.vue";
import ContasImporteMaintenance from "@/components/subviews/contas-importe/contas-importe-maintenance.vue";
import IContaImporte from "@/lib/interfaces/conta-importe";

export default Vue.extend({
  name: "ContasImporte",

  components: { ContasImporteList, ContasImporteMaintenance },

  data() {
    return {
      ret_id: 1,
      ret_msg: "",

      refresh: 0,

      currentTab: 0,

      contaImporte: {} as IContaImporte,
    };
  },
});
