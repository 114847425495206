
import Api from "@/lib/api";
import { Rq } from "@/lib/amdt";
import Vue, { PropType } from "vue";
import { DataOptions } from "vuetify";
import IContaImporte from "@/lib/interfaces/conta-importe";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ADataTable from "@/components/elements/a-data-table.vue";
import ACard from "@/components/elements/a-card.vue";
import ASelectStatus from "@/components/elements/a-select-status.vue";

export default Vue.extend({
  components: {
    vAlertMessage,
    ABtn,
    ATextField,
    ADataTable,
    ACard,
    ASelectStatus,
  },
  name: "ContasImporteList",

  props: {
    contaImporte: {
      type: Object as PropType<IContaImporte>,
      required: true,
    },

    forceRefresh: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      total: 0,
      search: "",
      desativado: "0",

      options: {} as DataOptions,
      contasImporte: new Array<IContaImporte>(),

      headers: [
        { text: "Descrição", value: "descricao", align: "start" },
        {
          text: "Status",
          value: "cDesativado",
          sortable: false,
          align: "center",
        },
        {
          text: "Ações",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
    };
  },

  watch: {
    forceRefresh() {
      this.getContasImporte().then();
    },

    options: {
      deep: true,
      handler() {
        this.getContasImporte().then();
      },
    },
  },

  computed: {
    cContasImporte(): Array<Record<string, unknown>> {
      return this.contasImporte.map((contaImporte) => ({
        ...contaImporte,
        cDesativado: contaImporte.desativado === "0" ? "Ativo" : "Inativo",
      }));
    },
  },

  methods: {
    async openContaImporte(id: string) {
      const contaImporte = this.contasImporte.find(
        (contaImporte) => contaImporte.id === id
      );
      this.$emit("update:conta-importe", { ...contaImporte });
    },

    async getContasImporte() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("conta_importe.read", {
          search: this.search,
          desativado: this.desativado,
          itemsPerPage: this.options.itemsPerPage.toString(),
          page: this.options.page.toString(),
          sortBy: this.options.sortBy[0] ?? "id",
          sortOrder: this.options.sortDesc[0] ? "DESC" : "ASC",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.total = Number(rsp.params["total"]);
        this.options.itemsPerPage = Number(rsp.params["itemsPerPage"]);
        this.options.page = Number(rsp.params["page"]);

        this.contasImporte =
          rsp.getTable("conta_importe")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
